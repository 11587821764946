.video-item
	@apply relative shadow-light rounded-5 overflow-hidden
	.img-scale
		@apply pt-[calc(250/350*100%)]
	.video-caption
		@apply absolute inset-0 flex-center z-11 transition-all duration-200 ease-in bg-black/85 text-white text-center flex-center flex-col p-3.75 gap-5 opacity-100 pointer-events-none
	.video-icon
		@apply transition-all duration-300 ease-in-out text-2xl xl:text-3xl
	.video-title
		@apply w-full transition-all duration-500 ease-in-out
	&:hover
		.video-icon
			@apply scale-150 translate-y-5
		.video-title
			@apply opacity-0 translate-y-10
