.home-banner
	@apply relative
	.img-scale
		@apply pt-[calc(960/1920*100%)] xl:pt-[calc(800/1920*100%)]
		&::before
			content: ''
			@apply absolute inset-0 z-1 bg-black/30 pointer-events-none
	.caption
		@apply absolute left-0 bottom-0 w-full z-1 px-3.75 py-5 text-white font-normal text-xl xl:text-3xl xl:px-10
	.swiper-button
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-1 text-white leading-none w-10 h-10 flex-center text-xl xl:text-4xl
		.button-prev
			@apply left-5
		.button-next
			@apply right-5
