@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

.button {
  @apply flex flex-wrap gap-5 {} }

.link {
  @apply flex-center space-x-2.5 font-bold text-base text-black xl:text-sm xl:hover:text-primary {} }
  .link i, .link em {
    @apply text-primary transition-all duration-200 ease-linear xl:text-xl xl:hover:text-secondary {} }
  .link.link-primary {
    @apply text-primary xl:hover:text-secondary {} }
  .link.link-gray-33 {
    @apply text-gray-33 xl:hover:text-secondary {} }
  .link.link-white {
    @apply text-white xl:hover:text-secondary {} }
    .link.link-white i, .link.link-white em {
      @apply text-white xl:hover:text-secondary {} }

.btn {
  @apply flex-center h-[calc(46/1920*100rem)] px-6 space-x-4 bg-primary font-normal text-base text-white border border-primary {} }
  .btn > br {
    @apply hidden {} }
  .btn .icon-arrow-long {
    @apply relative block h-px w-7.5 transition-all duration-200 ease-linear bg-current pointer-events-none {} }
    .btn .icon-arrow-long:before {
      content: '';
      @apply absolute bottom-0.75 right-0 rotate-45 h-px w-1.5 bg-current {} }
    .btn .icon-arrow-long:after {
      content: '';
      @apply absolute top-0.75 right-0 -rotate-45 h-px w-1.5 bg-current {} }

@screen xl {
  .btn:hover .icon-arrow-long {
    @apply w-15 {} } }
  .btn.btn-solid {
    @apply text-white {} }
    .btn.btn-solid.btn-primary {
      @apply bg-primary border-primary text-white {} }
    .btn.btn-solid.btn-black {
      @apply bg-black border-black text-white {} }
      .btn.btn-solid.btn-black.btn-transparent {
        @apply bg-transparent text-black {} }
      .btn.btn-solid.btn-black.btn-no-icon {
        @apply xl:hover:bg-white xl:hover:text-black {} }
    .btn.btn-solid.btn-secondary {
      @apply bg-secondary border-secondary text-white {} }
      .btn.btn-solid.btn-secondary.btn-transparent {
        @apply bg-transparent text-secondary {} }
      .btn.btn-solid.btn-secondary.btn-no-icon {
        @apply xl:hover:bg-white xl:hover:text-secondary {} }
    .btn.btn-solid.btn-white {
      @apply bg-white border-black text-black {} }
      .btn.btn-solid.btn-white.btn-bg-transparent {
        @apply bg-transparent text-white {} }
  .btn.btn-lined {
    @apply bg-white {} }
    .btn.btn-lined.btn-primary {
      @apply text-primary xl:hover:bg-primary xl:hover:text-white {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none  p-0 bg-none ml-4 {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-white rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1 {} }
  #buttonMenu .bar {
    @apply bg-white rounded-0.5 absolute shadow-bar {} }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.open .line:nth-child(2), #buttonMenu:hover .line:nth-child(2) {
    @apply w-[15px] {} }
  #buttonMenu.open .bar, #buttonMenu:hover .bar {
    @apply hidden {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop.open {
  @apply opacity-100 pointer-events-auto {} }

.nav-fixed ul {
  @apply space-y-2.5 {} }

.nav-fixed a {
  @apply flex-center w-15 h-15 border border-black bg-white text-black text-2xl md:w-10 md:h-10 md:text-base xl:hover:bg-black xl:hover:text-white {} }

.nav-fixed li.back-to-top {
  @apply opacity-0 pointer-events-none transition-all duration-300 ease-in-out {} }
  .nav-fixed li.back-to-top.active {
    @apply opacity-100 pointer-events-auto {} }

label {
  @apply w-full block text-lg font-light leading-normal text-gray-33 uppercase transition-all duration-200 ease-linear {} }

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='week'],
[multiple],
textarea {
  @apply relative w-full bg-transparent border border-0 border-b border-b-gray-33 h-12.5 px-0 py-1 text-sm text-black font-normal ring-transparent shadow-none ring-0 outline-none {}  @apply focus:z-1 focus:ring-0 focus:border-b-black {}  @apply placeholder:text-black {}  @apply disabled:bg-gray-e5 disabled:border-gray-e5 disabled:border-b-gray-cc disabled:text-black {}  @apply read-only:bg-gray-e5 read-only:border-gray-e5 read-only:border-b-gray-cc read-only:text-black {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%230B3F7E' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>");
  @apply relative w-full bg-transparent border border-black h-10 pl-4 pr-10 py-1 text-sm text-black font-normal {}  @apply focus:z-1 {} }

textarea {
  @apply h-25 py-3.75 {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-gray-e5 border-none h-12 px-6 py-3 text-base text-primary font-normal flex-center space-x-3 cursor-pointer m-0 transition-all duration-200 ease-linear xl:hover:bg-gray-cc {} }

.btn-submit,
input[type="submit"],
button[type="submit"] {
  @apply flex h-12.5 px-6 gap-4 text-base font-normal text-black text-center border border-black bg-white transition-all duration-200 ease-linear uppercase hover:bg-black hover:text-white {} }

.form-group {
  @apply relative mb-5 xl:mb-6 {} }
  .form-group > span, .form-group > div > span {
    @apply text-xs text-secondary italic block mt-0.5 font-primary font-normal {} }

.modal {
  @apply p-0 w-full max-w-[calc(1440/1920*100rem)] {} }
  .modal .modal-wrap {
    @apply p-6.5 md:p-10 xl:p-15 {} }
  .modal .modal-head {
    @apply pb-4 {} }
  .modal .modal-title {
    @apply text-xl font-normal leading-1.3 text-center uppercase text-secondary {} }

.modal-events-ongoing {
  @apply p-0 max-w-[calc(930/1920*100rem)] {} }
  .modal-events-ongoing .modal-wrap {
    @apply xl:pt-14 xl:pb-16 {} }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.tabslet-tabs {
  @apply flex-center mb-10 gap-5 md:gap-7.5 xl:gap-10 {} }
  .tabslet-tabs a {
    @apply text-lg font-body text-white inline-block relative {} }
    .tabslet-tabs a::before {
      content: '';
      background: linear-gradient(0deg, theme("colors.white"), theme("colors.white")) no-repeat right bottom/0 theme("spacing[px]");
      transition: background-size 350ms;
      @apply absolute bottom-0 left-0 w-full h-px z-1 pointer-events-none {} }
    .tabslet-tabs a:hover::before {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .tabslet-tabs li.active a::before {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300; }

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }

.heading-1-title {
  @apply text-5xl leading-normal xl:text-6xl {} }

.heading-2-title {
  @apply text-4xl leading-normal xl:text-5xl {} }

.heading-3-title {
  @apply text-3xl leading-normal xl:text-4xl {} }

.heading-4-title {
  @apply text-2xl leading-normal xl:text-3xl {} }

.heading-5-title {
  @apply text-xl leading-normal xl:text-2xl {} }

.body-1-text {
  @apply text-xl leading-snug {} }

.body-2-text {
  @apply text-lg leading-snug {} }

.body-3-text {
  @apply text-base leading-snug {} }

.body-4-text {
  @apply text-sm leading-snug {} }

.body-5-text {
  @apply text-xs leading-snug {} }

@layer base {
  html, body {
    font-size: 14px; }
  @screen md {
    html, body {
      font-size: 15px; } }
  @screen xl {
    html, body {
      font-size: 1.25vw; } }
  @screen 2xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply text-main font-primary bg-white {} }
  h1 {
    @apply text-3xl font-bold {} }
  h2 {
    @apply text-2xl font-bold {} }
  h3 {
    @apply text-xl font-bold {} }
  h4 {
    @apply text-lg font-bold {} }
  h5 {
    @apply text-base font-bold {} }
  h6 {
    @apply text-sm font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 xl:hover:text-current {} }
  img {
    @apply inline {} }
  main, div {
    @apply xl:text-base {} } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-[calc(555/1920*100rem)] {} } }
  @screen sm {
    .container {
      @apply max-w-[calc(576/1920*100rem)] {} } }
  @screen md {
    .container {
      @apply max-w-[calc(768/1920*100rem)] {} } }
  @screen lg {
    .container {
      @apply max-w-[calc(1024/1920*100rem)] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1280/1920*100rem)] {} } }
  @screen 2xl {
    .container {
      @apply max-w-[calc(1440/1920*100rem)] {} } }
  .text-group {
    @apply py-10 space-y-7.5 md:p-12 xl:p-15 xl:pt-20 {} }
  .full-content {
    @apply text-[1rem] md:text-lg xl:text-base {} }
    .full-content * {
      @apply mb-4 last:mb-0 {} }
    .full-content ul, .full-content ol {
      list-style: initial;
      @apply pl-5 {} }
    .full-content table {
      @apply w-full {} }
    .full-content th, .full-content td {
      @apply border border-solid border-black p-2.5 md:p-5 {} }
    .full-content th {
      @apply bg-black text-white text-base leading-[calc(12/16)] font-normal border-r-white/30 last:border-r-black {} }
    .full-content td {
      @apply text-black align-top text-sm leading-[calc(16/14)] font-light border-r-gray-cc last:border-r-black {} }
    .full-content figure {
      @apply max-w-full {} }
    .full-content a {
      @apply font-medium text-blue {} }
  .hover-underline {
    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms; }
    @media (min-width: 1280px) {
      .hover-underline:hover {
        background-size: 100% theme("spacing[px]");
        background-position-x: left; } } }

@layer utilities {
  .container-fuild {
    @apply px-3.75 {} }
  .section-py {
    @apply py-10 md:py-15 xl:py-20 {} }
  .row {
    @apply flex flex-wrap -mx-3.75 {} }
    .row > * {
      @apply px-3.75 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .global-title {
    @apply font-normal mb-10 text-primary uppercase tracking-wider xl:text-4xl {} }
  .secondary-title {
    @apply text-2xl font-bold leading-[calc(39/32)] md:text-3xl xl:text-32 {} }
  .global-desc {
    @apply text-[1rem] leading-[calc(22/18)] space-y-4 xl:text-lg xl:space-y-4.5 {} }
  .swiper-relative {
    @apply relative {} }
  .swiper-webgl {
    @apply w-full h-full {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in:hover img {
    @apply scale-110 {} } }

.about-section .global-title {
  @apply mb-5 xl:mb-7.5 {} }

.about-section .about-wrap .global-desc {
  @apply xl:text-base xl:pr-10 {} }

.about-section .wrap-form > * {
  @apply mb-5 xl:mb-7.5 {} }

.home-banner {
  @apply relative {} }
  .home-banner .img-scale {
    @apply pt-[calc(960/1920*100%)] xl:pt-[calc(800/1920*100%)] {} }
    .home-banner .img-scale::before {
      content: '';
      @apply absolute inset-0 z-1 bg-black/30 pointer-events-none {} }
  .home-banner .caption {
    @apply absolute left-0 bottom-0 w-full z-1 px-3.75 py-5 text-white font-normal text-xl xl:text-3xl xl:px-10 {} }
  .home-banner .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 text-white leading-none w-10 h-10 flex-center text-xl xl:text-4xl {} }
  .home-banner .swiper-button .button-prev {
    @apply left-5 {} }
  .home-banner .swiper-button .button-next {
    @apply right-5 {} }

.before-after-detail .twentytwenty-handle {
  @apply bg-transparent {} }

.before-after-detail .before-after-wrap {
  @apply xl:max-w-5xl xl:mx-auto {} }

.before-after-item {
  @apply relative shadow-light rounded-5 overflow-hidden {} }
  .before-after-item .img-scale {
    @apply pt-[calc(250/350*100%)] {} }
  .before-after-item .before-after-caption {
    @apply absolute inset-0 flex-center z-11 transition-all duration-200 ease-in bg-black/90 text-white text-center flex-center flex-col p-3.75 gap-5 opacity-0 pointer-events-none {} }
  .before-after-item .before-after-icon {
    @apply rotate-90 translate-y-5 transition-all duration-300 ease-in-out text-2xl {} }
  .before-after-item .before-after-title {
    @apply w-full translate-y-7.5 transition-all duration-500 ease-in-out {} }
  .before-after-item:hover .before-after-caption {
    @apply opacity-100 {} }
  .before-after-item:hover .before-after-icon, .before-after-item:hover .before-after-title {
    @apply translate-y-0 {} }

.gallery-item {
  @apply relative shadow-light overflow-hidden {} }
  .gallery-item .gallery-image img {
    @apply w-full h-full object-contain {} }
  .gallery-item .gallery-caption {
    @apply absolute inset-0 flex-center z-11 transition-all duration-200 ease-in bg-black/90 text-white text-center flex-center flex-col p-3.75 gap-5 opacity-0 pointer-events-none {} }
  .gallery-item .gallery-icon {
    @apply translate-y-5 transition-all duration-300 ease-in-out text-2xl {} }
  .gallery-item .gallery-title {
    @apply w-full translate-y-7.5 transition-all duration-500 ease-in-out {} }
  .gallery-item:hover .gallery-caption {
    @apply opacity-100 {} }
  .gallery-item:hover .gallery-icon, .gallery-item:hover .gallery-title {
    @apply translate-y-0 {} }

.nat-header {
  @apply static py-10 {} }
  .nat-header .nat-name {
    @apply text-8xl text-center text-primary font-normal tracking-widest uppercase xl:text-9xl {} }
    .nat-header .nat-name img {
      @apply max-h-[calc(150/1920*100rem)] w-full h-full object-contain {} }
  .nat-header .nat-sub-name {
    @apply text-3xl text-center text-primary font-normal tracking-widest uppercase md:w-max md:mx-auto xl:text-2xl {} }
    .nat-header .nat-sub-name > * {
      @apply block {} }

@screen md {
  .nat-header .nat-sub-name > * + * {
    @apply relative {}    @apply before:content-['|'] before:text-3xl before:text-primary before:font-bold before:absolute before:top-1/2 before:-translate-y-1/2 before:-left-7.5 before:font-normal before:pointer-events-none {} } }

.gallery-filter-tab {
  @apply flex items-center justify-center gap-7.5 xl:gap-10 {} }
  .gallery-filter-tab li {
    background: linear-gradient(0deg, theme("colors.primary"), theme("colors.primary")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms;
    @apply block text-2xl cursor-pointer {} }
    .gallery-filter-tab li.active, .gallery-filter-tab li:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  @media (max-width: 575.89px) {
    .gallery-filter-tab {
      @apply justify-start overflow-x-auto overflow-y-hidden pb-5 {} }
      .gallery-filter-tab li {
        @apply w-max whitespace-nowrap {} } }

.gallery-list {
  @apply max-w-full w-full {}  @apply mt-10 {} }
  .gallery-list > * {
    @apply w-full md:w-[calc((100%/2)-30px)] lg:w-[calc((100%/3)-30px)] {} }

.home-gallery .global-title {
  @apply text-center {} }

.video-item {
  @apply relative shadow-light rounded-5 overflow-hidden {} }
  .video-item .img-scale {
    @apply pt-[calc(250/350*100%)] {} }
  .video-item .video-caption {
    @apply absolute inset-0 flex-center z-11 transition-all duration-200 ease-in bg-black/85 text-white text-center flex-center flex-col p-3.75 gap-5 opacity-100 pointer-events-none {} }
  .video-item .video-icon {
    @apply transition-all duration-300 ease-in-out text-2xl xl:text-3xl {} }
  .video-item .video-title {
    @apply w-full transition-all duration-500 ease-in-out {} }
  .video-item:hover .video-icon {
    @apply scale-150 translate-y-5 {} }
  .video-item:hover .video-title {
    @apply opacity-0 translate-y-10 {} }

.button-language .wpml-ls-legacy-dropdown-click {
  @apply w-auto max-w-none {} }
  .button-language .wpml-ls-legacy-dropdown-click .wpml-ls-item-toggle {
    @apply whitespace-nowrap cursor-pointer border-none text-current p-0 m-0 w-10 h-10 bg-transparent flex-center xl:hover:text-primary {}    @apply focus:outline focus:outline-dashed focus:outline-current {}    @apply after:hidden {} }
  .button-language .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu::after {
    content: '';
    @apply absolute bottom-full h-2 left-0 w-full z-1 bg-transparent {} }
  .button-language .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu a {
    @apply block py-2 px-3.75 xl:hover:bg-gray-f5 xl:hover:text-primary {} }
  .button-language .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu li.active {
    @apply text-primary {} }

footer {
  @apply py-10 text-primary shadow-card {} }
  footer .footer-logo {
    @apply flex-center h-25 text-6xl font-normal tracking-widest {} }
    footer .footer-logo img {
      @apply w-full h-full object-contain {} }
  footer .footer-caption {
    @apply text-3xl font-light tracking-wider text-center {} }
  footer .footer-social-list {
    @apply flex-center gap-5 {} }
    footer .footer-social-list a {
      @apply flex-center text-2xl w-8 h-8 xl:hover:text-primary {} }
  footer .footer-menu-list {
    @apply flex-center gap-5 {} }
  footer .footer-copyright {
    @apply text-sm font-normal text-center {} }
