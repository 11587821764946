.button
	@apply flex flex-wrap gap-5

.link
	@apply flex-center space-x-2.5 font-bold text-base text-black xl:text-sm xl:hover:text-primary
	i,em
		@apply text-primary transition-all duration-200 ease-linear xl:text-xl xl:hover:text-secondary
	&.link-primary
		@apply text-primary xl:hover:text-secondary
	&.link-gray-33
		@apply text-gray-33 xl:hover:text-secondary
	&.link-white
		@apply text-white xl:hover:text-secondary
		i,em
			@apply text-white xl:hover:text-secondary

.btn
	@apply flex-center h-[calc(46/1920*100rem)] px-6 space-x-4 bg-primary font-normal text-base text-white border border-primary
	> br
		@apply hidden
	.icon-arrow-long
		@apply relative block h-px w-7.5 transition-all duration-200 ease-linear bg-current pointer-events-none
		&:before
			content: ''
			@apply absolute bottom-0.75 right-0 rotate-45 h-px w-1.5 bg-current
		&:after
			content: ''
			@apply absolute top-0.75 right-0 -rotate-45 h-px w-1.5 bg-current
	@screen xl
		&:hover
			.icon-arrow-long
				@apply w-15
	&.btn-solid
		@apply text-white
		&.btn-primary
			@apply bg-primary border-primary text-white
		&.btn-black
			@apply bg-black border-black text-white
			&.btn-transparent
				@apply bg-transparent text-black
			&.btn-no-icon
				@apply xl:hover:bg-white xl:hover:text-black
		&.btn-secondary
			@apply bg-secondary border-secondary text-white
			&.btn-transparent
				@apply bg-transparent text-secondary
			&.btn-no-icon
				@apply xl:hover:bg-white xl:hover:text-secondary
		&.btn-white
			@apply bg-white border-black text-black
			&.btn-bg-transparent
				@apply bg-transparent text-white
	&.btn-lined
		@apply bg-white
		&.btn-primary
			@apply text-primary xl:hover:bg-primary xl:hover:text-white
