footer
	@apply py-10 text-primary shadow-card
	.footer-logo
		@apply flex-center h-25 text-6xl font-normal tracking-widest
		img
			@apply w-full h-full object-contain
	.footer-caption
		@apply text-3xl font-light tracking-wider text-center
	.footer-social-list
		@apply flex-center gap-5
		a
			@apply flex-center text-2xl w-8 h-8 xl:hover:text-primary
	.footer-menu-list
		@apply flex-center gap-5
	.footer-copyright
		@apply text-sm font-normal text-center
