.modal
	@apply p-0 w-full max-w-[calc(1440/1920*100rem)]
	.modal-wrap
		@apply p-6.5 md:p-10 xl:p-15
	.modal-head
		@apply pb-4
	.modal-title
		@apply text-xl font-normal leading-1.3 text-center uppercase text-secondary

.modal-events-ongoing
	@apply p-0 max-w-[calc(930/1920*100rem)]
	.modal-wrap
		@apply xl:pt-14 xl:pb-16
