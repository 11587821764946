.gallery-item
	@apply relative shadow-light overflow-hidden
	.gallery-image
		img
			@apply w-full h-full object-contain
	.gallery-caption
		@apply absolute inset-0 flex-center z-11 transition-all duration-200 ease-in bg-black/90 text-white text-center flex-center flex-col p-3.75 gap-5 opacity-0 pointer-events-none
	.gallery-icon
		@apply translate-y-5 transition-all duration-300 ease-in-out text-2xl
	.gallery-title
		@apply w-full translate-y-7.5 transition-all duration-500 ease-in-out
	&:hover
		.gallery-caption
			@apply opacity-100
		.gallery-icon, .gallery-title
			@apply translate-y-0
	// &.video
	// 	@apply
