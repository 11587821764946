.tabslet-tabs
	@apply flex-center mb-10 gap-5 md:gap-7.5 xl:gap-10
	a
		@apply text-lg font-body text-white inline-block relative
		&::before
			content: ''
			background: linear-gradient(0deg, theme('colors.white'), theme('colors.white')) no-repeat right bottom / 0 theme('spacing[px]')
			transition: background-size 350ms
			@apply absolute bottom-0 left-0 w-full h-px z-1 pointer-events-none
		&:hover
			&::before
				background-size: 100% theme('spacing[px]')
				background-position-x: left
	li
		&.active
			a
				&::before
					background-size: 100% theme('spacing[px]')
					background-position-x: left
