@layer components
	.container
		@screen xs
			@apply max-w-[calc(555/1920*100rem)]
		@screen sm
			@apply max-w-[calc(576/1920*100rem)]
		@screen md
			@apply max-w-[calc(768/1920*100rem)]
		@screen lg
			@apply max-w-[calc(1024/1920*100rem)]
		@screen xl
			@apply max-w-[calc(1280/1920*100rem)]
		@screen 2xl
			@apply max-w-[calc(1440/1920*100rem)]

	.text-group
		@apply py-10 space-y-7.5 md:p-12 xl:p-15 xl:pt-20

	.full-content
		@apply text-[1rem] md:text-lg xl:text-base
		*
			@apply mb-4 last:mb-0
		ul, ol
			list-style: initial
			@apply pl-5
		table
			@apply w-full
		th, td
			@apply border border-solid border-black p-2.5 md:p-5
		th
			@apply bg-black text-white text-base leading-[calc(12/16)] font-normal border-r-white/30 last:border-r-black
		td
			@apply text-black align-top text-sm leading-[calc(16/14)] font-light border-r-gray-cc last:border-r-black
		figure
			@apply max-w-full
		a
			@apply font-medium text-blue

	.hover-underline
		background: linear-gradient(0deg, theme('colors.current'), theme('colors.current')) no-repeat right bottom / 0 theme('spacing[px]')
		transition: background-size 350ms
		@media(min-width: 1280px)
			&:hover
				background-size: 100% theme('spacing[px]')
				background-position-x: left
