.button-language
	.wpml-ls-legacy-dropdown-click
		@apply w-auto max-w-none
		.wpml-ls-item-toggle
			@apply whitespace-nowrap cursor-pointer border-none text-current p-0 m-0 w-10 h-10 bg-transparent flex-center xl:hover:text-primary
			@apply focus:outline focus:outline-dashed focus:outline-current
			@apply after:hidden
		.wpml-ls-sub-menu
			&::after
				content: ''
				@apply absolute bottom-full h-2 left-0 w-full z-1 bg-transparent
			a
				@apply block py-2 px-3.75 xl:hover:bg-gray-f5 xl:hover:text-primary
			li
				&.active
					@apply text-primary
