label
	@apply w-full block text-lg font-light leading-normal text-gray-33 uppercase transition-all duration-200 ease-linear

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='week'],
[multiple],
textarea
	@apply relative w-full bg-transparent border border-0 border-b border-b-gray-33 h-12.5 px-0 py-1 text-sm text-black font-normal ring-transparent shadow-none ring-0 outline-none
	@apply focus:z-1 focus:ring-0 focus:border-b-black
	@apply placeholder:text-black
	@apply disabled:bg-gray-e5 disabled:border-gray-e5 disabled:border-b-gray-cc disabled:text-black
	@apply read-only:bg-gray-e5 read-only:border-gray-e5 read-only:border-b-gray-cc read-only:text-black
	// &:not(:placeholder-shown), &:focus
	// 	& ~ label
	// 		@apply -top-3 text-sm opacity-50

select
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%230B3F7E' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>")
	@apply relative w-full bg-transparent border border-black h-10 pl-4 pr-10 py-1 text-sm text-black font-normal
	@apply focus:z-1

textarea
	@apply h-25 py-3.75

input[type='file']
	@apply hidden
	~ label
		@apply relative w-max bg-gray-e5 border-none h-12 px-6 py-3 text-base text-primary font-normal flex-center space-x-3 cursor-pointer m-0 transition-all duration-200 ease-linear xl:hover:bg-gray-cc

.btn-submit,
input[type="submit"],
button[type="submit"]
	@apply flex h-12.5 px-6 gap-4 text-base font-normal text-black text-center border border-black bg-white transition-all duration-200 ease-linear uppercase hover:bg-black hover:text-white

.form-group
	@apply relative mb-5 xl:mb-6
	> span, > div > span
		@apply text-xs text-secondary italic block mt-0.5 font-primary font-normal
