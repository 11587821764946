.nat-header
	@apply static py-10
	.nat-name
		@apply text-8xl text-center text-primary font-normal tracking-widest uppercase xl:text-9xl
		img
			@apply max-h-[calc(150/1920*100rem)] w-full h-full object-contain
	.nat-sub-name
		@apply text-3xl text-center text-primary font-normal tracking-widest uppercase md:w-max md:mx-auto xl:text-2xl
		> *
			@apply block
			@screen md
				& + *
					@apply relative
					@apply before:content-['|'] before:text-3xl before:text-primary before:font-bold before:absolute before:top-1/2 before:-translate-y-1/2 before:-left-7.5 before:font-normal before:pointer-events-none

.gallery-filter-tab
	@apply flex items-center justify-center gap-7.5 xl:gap-10
	li
		background: linear-gradient(0deg, theme('colors.primary'), theme('colors.primary')) no-repeat right bottom / 0 theme('spacing[px]')
		transition: background-size 350ms
		@apply block text-2xl cursor-pointer
		&.active, &:hover
			background-size: 100% theme('spacing[px]')
			background-position-x: left
	@media(max-width: 575.89px)
		@apply justify-start overflow-x-auto overflow-y-hidden pb-5
		li
			@apply w-max whitespace-nowrap

.gallery-list
	@apply max-w-full w-full
	@apply mt-10
	> *
		@apply w-full md:w-[calc((100%/2)-30px)] lg:w-[calc((100%/3)-30px)]
	// 	@screen lg
	// 		grid-column-start: span 4
	// 		&:nth-child(9n+1)
	// 			@apply mb-[18%]
	// 		&:nth-child(9n+2)
	// 			@apply mt-[36%] mr-[12%] ml-[18%]
	// 		&:nth-child(9n+3)
	// 			@apply mt-[6%]
	// 		&:nth-child(9n+4)
	// 			grid-column-start: span 3
	// 			@apply mb-[12%]
	// 		&:nth-child(9n+5)
	// 			grid-column-start: span 5
	// 			@apply my-[14%]
	// 		&:nth-child(9n+6)
	// 			grid-column-start: span 4
	// 			@apply mt-[30%] mb-[15%] ml-[10%]
	// 		&:nth-child(9n+7)
	// 			grid-column-start: span 5
	// 			@apply mt-[24%] mr-[9%] mb-[20%]
	// 		&:nth-child(9n+8)
	// 			grid-column-start: span 4
	// 			@apply mr-[10%] ml-[4%]
	// 		&:nth-child(9n+9)
	// 			grid-column-start: span 3
	// 			@apply mt-[28%]
	// 		&:nth-last-child(-n+3)
	// 			@apply mb-0

.home-gallery
	.global-title
		@apply text-center
