.before-after-item
	@apply relative shadow-light rounded-5 overflow-hidden
	.img-scale
		@apply pt-[calc(250/350*100%)]
	.before-after-caption
		@apply absolute inset-0 flex-center z-11 transition-all duration-200 ease-in bg-black/90 text-white text-center flex-center flex-col p-3.75 gap-5 opacity-0 pointer-events-none
	.before-after-icon
		@apply rotate-90 translate-y-5 transition-all duration-300 ease-in-out text-2xl
	.before-after-title
		@apply w-full translate-y-7.5 transition-all duration-500 ease-in-out
	&:hover
		.before-after-caption
			@apply opacity-100
		.before-after-icon, .before-after-title
			@apply translate-y-0
