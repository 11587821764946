#buttonMenu
	@apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none  p-0 bg-none ml-4
	.line
		@apply absolute block h-[2px] w-full bg-white rounded-full opacity-100 left-0 rotate-0 transition-all
		&:nth-child(1)
			@apply top-[4px]
		&:nth-child(2)
			@apply top-1/2 -translate-y-1/2
		&:nth-child(3)
			@apply bottom-[4px]
	#pulseMe
		@apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1
	.bar
		@apply bg-white rounded-0.5 absolute shadow-bar
		&.left
			@apply w-px animate-left-bar
		&.top
			@apply h-px animate-top-bar
		&.right
			@apply w-px animate-right-bar
		&.bottom
			@apply h-px animate-bottom-bar
	&.open, &:hover
		.line
			&:nth-child(2)
				@apply w-[15px]
		.bar
			@apply hidden
	@screen xl
		display: none !important

.alert
	@apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm
	& button
		@apply hidden
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb

.backdrop
	&.open
		@apply opacity-100 pointer-events-auto

.nav-fixed
	ul
		@apply space-y-2.5
	a
		@apply flex-center w-15 h-15 border border-black bg-white text-black text-2xl md:w-10 md:h-10 md:text-base xl:hover:bg-black xl:hover:text-white
	li
		&.back-to-top
			@apply opacity-0 pointer-events-none transition-all duration-300 ease-in-out
			&.active
				@apply opacity-100 pointer-events-auto
