@layer utilities
	.container-fuild
		@apply px-3.75

	.section-py
		@apply py-10 md:py-15 xl:py-20

	.row
		@apply flex flex-wrap -mx-3.75
		> *
			@apply px-3.75

	.no-gutters
		@apply mx-0
		> *
			@apply px-0

	.global-title
		@apply font-normal mb-10 text-primary uppercase tracking-wider xl:text-4xl

	.secondary-title
		@apply text-2xl font-bold leading-[calc(39/32)] md:text-3xl xl:text-32

	.global-desc
		@apply text-[1rem] leading-[calc(22/18)] space-y-4 xl:text-lg xl:space-y-4.5

	.swiper-relative
		@apply relative

	.swiper-webgl
		@apply w-full h-full

	.writing-mode-tb-rl
		writing-mode: vertical-rl
		text-orientation: mixed

	.media-scale
		@apply relative block h-0 overflow-hidden
		img, iframe, video
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out

	.img-scale
		@apply relative block h-0 overflow-hidden
		img
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover

	.iframe-scale
		@apply relative block h-0 overflow-hidden
		iframe
			@apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out

	.video-scale
		@apply relative block h-0 overflow-hidden
		video
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover

	.bg-bright-grey
		@apply backdrop-blur-25

	.absolute-center
		@apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2

	.filter-white
		@apply brightness-0 invert

	.img-zoom-in
		&:hover
			img
				@apply scale-110
